import React from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

//SVG
import Cost from "../../images/Construction/Cost.svg"
import Quality from "../../images/Construction/Quality.svg"
import Rated from "../../images/Construction/Rated.svg"

const ConstructionPage = () => {
  const data = useStaticQuery(graphql`
    query {
      worker: file(relativePath: { eq: "Construction/Worker.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      constructor: file(relativePath: { eq: "Construction/Construct.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image1: file(relativePath: { eq: "Construction/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2: file(relativePath: { eq: "Construction/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3: file(relativePath: { eq: "Construction/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image4: file(relativePath: { eq: "Construction/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image5: file(relativePath: { eq: "Construction/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <section className={styles.constructionSection}>
      <Container>
        <section className={styles.constructionHeader}>
          <div className={styles.constructionHeaderText}>
            <h1>
              Build a Better Tommorow<span>.</span>
            </h1>
            <p>
              A leading developer of A-grade Commercial and Residential projects
              in India. Since it's foundation we have doubled over turnover year
              on year, with our staff numbers swelling accordingly.
            </p>
          </div>
          <div className={styles.constructionHeaderImage}>
            <Img fluid={data.worker.childImageSharp.fluid} />
          </div>
        </section>
      </Container>

      <section className={styles.construction}>
        <Container>
          <section className={styles.constructionData}>
            <div className={styles.constructionDataImage}>
              <Img fluid={data.constructor.childImageSharp.fluid} />
            </div>
            <div className={styles.constructionDataDetails}>
              <h1>
                Exceptional quality construction, unparalleled service, superior
                safety.
              </h1>
              <h3>
                Integrity, a solution-driven mindset and helping our clients
                achieve the most for their resources is how UK Group delivers
                value. See what sets us apart and has earned us an enviable
                reputation with clients, industry partners and employees alike.{" "}
              </h3>
              <p>
                UK Group is recognized for its well-coordinated, solution-driven
                approach to construction and represents a diverse portfolio of
                clients and projects. Our firm is responsible for building
                beautiful and well structured Comercial Complexes and
                Residential Buildings, as well as countless properties that
                subtly define and serve our communities.{" "}
              </p>
              <p>
                Built over generations, by generations, UK Group is committed to
                bringing together talented people who have a passion for
                building. Focused on client service, camaraderie and cohesion,
                our team-focused mentality translates directly to how we treat
                our building partners and clients.{" "}
              </p>

              {/* <div className={styles.constructionDataDetailsNumbers}>
                <div className={styles.constructionDataDetailsNumbersDetails}>
                  <h1
                    className={
                      styles.constructionDataDetailsNumbersDetailsHeading
                    }
                  >
                    945
                  </h1>
                  <h3
                    className={styles.constructionDataDetailsNumbersDetailsSub}
                  >
                    Projects Completed
                  </h3>
                </div>
                <div className={styles.constructionDataDetailsNumbersDetails}>
                  <h1
                    className={
                      styles.constructionDataDetailsNumbersDetailsHeading
                    }
                  >
                    42
                  </h1>
                  <h3
                    className={styles.constructionDataDetailsNumbersDetailsSub}
                  >
                    Happy Clients{" "}
                  </h3>
                </div>
                <div className={styles.constructionDataDetailsNumbersDetails}>
                  <h1
                    className={
                      styles.constructionDataDetailsNumbersDetailsHeading
                    }
                  >
                    6,345
                  </h1>
                  <h3
                    className={styles.constructionDataDetailsNumbersDetailsSub}
                  >
                    Worker Employed
                  </h3>
                </div>
              </div> */}
            </div>
          </section>

          <section className={styles.choose}>
            <div className={styles.chooseText}>
              <h1 className={styles.chooseTextHeading}>Why Choose Us</h1>
              <p className={styles.chooseTextSub}>
                Complete control over products allows us to ensure our customers
                receive the best quality prices and service. We take pride in
                the work that we provide.{" "}
              </p>
            </div>

            <div className={styles.chooseOptions}>
              <div className={styles.chooseOptionsBox}>
                <img
                  src={Cost}
                  alt="Cost"
                  className={styles.chooseOptionsBoxIcons}
                ></img>
                <h1>Low Cost</h1>
                <p>
                  We offer best possible rates in the market and ensure our
                  clients that they get the best for what they pay for.
                </p>
              </div>
              <div className={styles.chooseOptionsBox}>
                <img
                  src={Quality}
                  alt="Quality"
                  className={styles.chooseOptionsBoxIcons}
                ></img>
                <h1>Best Quality</h1>
                <p>
                  With the price we we offer we ensure our clients receive top
                  of the line quality in construction.
                </p>
              </div>
              <div className={styles.chooseOptionsBox}>
                <img
                  src={Rated}
                  alt="Rated"
                  className={styles.chooseOptionsBoxIcons}
                ></img>
                <h1>Top Rated</h1>
                <p>
                  Trusted by clients by all these years, We make sure our work
                  remains the same and there is no compromise to the work we
                  offer.
                </p>
              </div>
            </div>
          </section>

          <section className={styles.project}>
            <h1 className={styles.projectHeading}>Latest Projects</h1>
            <div className={styles.projectImages}>
              <figure className={styles.projectImage__1}>
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  className={styles.projectImage}
                  alt="Image 1"
                />
              </figure>
              <figure className={styles.projectImage__2}>
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  className={styles.projectImage}
                  alt="Image 2"
                />
              </figure>
              <figure className={styles.projectImage__3}>
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  className={styles.projectImage}
                  alt="Image 3"
                />
              </figure>
              <figure className={styles.projectImage__4}>
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  className={styles.projectImage}
                  alt="Image 4"
                />
              </figure>
              <figure className={styles.projectImage__5}>
                <Img
                  fluid={data.image5.childImageSharp.fluid}
                  className={styles.projectImage}
                  alt="Image 5"
                />
              </figure>
            </div>
          </section>
        </Container>
      </section>
    </section>
  )
}

export default ConstructionPage
