import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Construction from "../../components/ConstructionPage/ConstructionPage"

const construction = () => {
  return (
    <Layout>
      <Seo title="Construction" />
      <Construction />
    </Layout>
  )
}

export default construction
