// extracted by mini-css-extract-plugin
export var choose = "styles-module--choose--03c6c";
export var chooseOptions = "styles-module--chooseOptions--66841";
export var chooseOptionsBox = "styles-module--chooseOptionsBox--0c4eb";
export var chooseOptionsBoxIcons = "styles-module--chooseOptionsBoxIcons--24785";
export var chooseText = "styles-module--chooseText--b9590";
export var chooseTextHeading = "styles-module--chooseTextHeading--0ba31";
export var chooseTextSub = "styles-module--chooseTextSub--e6445";
export var construction = "styles-module--construction--05ca1";
export var constructionData = "styles-module--constructionData--b38e1";
export var constructionDataDetails = "styles-module--constructionDataDetails--3dad8";
export var constructionDataDetailsNumbers = "styles-module--constructionDataDetailsNumbers--c3d3a";
export var constructionDataDetailsNumbersDetails = "styles-module--constructionDataDetailsNumbersDetails--78c8a";
export var constructionDataImage = "styles-module--constructionDataImage--2180e";
export var constructionHeader = "styles-module--constructionHeader--9254f";
export var constructionHeaderImage = "styles-module--constructionHeaderImage--82368";
export var constructionHeaderText = "styles-module--constructionHeaderText--a3ec6";
export var constructionSection = "styles-module--constructionSection--34170";
export var project = "styles-module--project--aefa1";
export var projectHeading = "styles-module--projectHeading--cd3ef";
export var projectImage = "styles-module--projectImage--799ce";
export var projectImage__1 = "styles-module--projectImage__1--3f2c1";
export var projectImage__2 = "styles-module--projectImage__2--06a63";
export var projectImage__3 = "styles-module--projectImage__3--212c3";
export var projectImage__4 = "styles-module--projectImage__4--ef262";
export var projectImage__5 = "styles-module--projectImage__5--102b1";
export var projectImages = "styles-module--projectImages--b1b3d";